.custom-slider .slick-dots {
    bottom: 0px;
}

.custom-slider .slick-dots li {
    width: 30px;
    height: 20px;

}

.custom-slider .slick-dots .slick-active {
    opacity: 1;
}

.custom-slider .slick-dots li button {
    width: 30px;
    height: 4px;
    background: #ffffff;
    border-radius: 15px;
    opacity: 0.5;
    padding: 0;
}

.custom-slider .slick-dots .slick-active button {
    opacity: 1;
}

.custom-slider .slick-dots li:hover button {
    opacity: 0.75;
}

.slick-dots li button:before {
    color: transparent;
}

.custom-slider .slick-dots .slick-active button:before {
    color: transparent;
}