.react-datepicker-wrapper {
    width: 100%;
}

::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}

::-webkit-scrollbar-track {
    background: #E9EDF7;
    margin: 12px 0;
}

::-webkit-scrollbar-thumb {
    background: #ABB0B5;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ABB0B5;
}

.react-datepicker__time-container {
    position: absolute;
    right: -85px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 16px;
}

.datepickerContainer {
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    color: #000;
    border-radius: 30px;
    display: inline-block;
    position: relative;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.react-datepicker__header {
    text-align: center;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
    background-color: white;
    border-bottom: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
    background: #3D9897 !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    color: white !important;
    outline: none !important;
    border: none;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 10px;
    background-color: #E0E5F2;
    border: none !important;
    color: white !important;
    outline: none !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
    background: #3D9897;
    opacity: 0.8;
}

.react-datepicker__day {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    padding: 5px;
    margin: 2px 5px;
    color: rgba(27, 37, 89, 1);
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 5px 8px;
    text-transform: uppercase;
    background: rgba(244, 247, 254, 1);
}

.react-datepicker__day-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
}



.datepicker-day-wrapper {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: rgba(27, 37, 89, 1);
    border-radius: 0.3rem;
}

.datepicker-day-wrapper__outside-month {
    color: lightgray;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #3D9897;
}

.react-datepicker__day--disabled {
    opacity: 0.3;
}

.react-datepicker__day--today {
    color: 'black';
    font-weight: 700;
}

.react-datepicker-popper .react-datepicker__triangle {
    stroke: transparent;
    color: white !important;
    fill: white !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range) {
    background-color: rgba(61, 152, 150, 0.5);
}