.ck-editor .ck.ck-toolbar {
  background: var(--chakra-colors-mainGray-200);
  border-radius: 10px 10px 0 0;
}

.ck-editor .ck.ck-editor__top .ck-sticky-panel__content {
  border: 0 none;
}


.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  background-color: var(--chakra-colors-gray-100);
  border-radius: 0 0 10px 10px;
  font-size: 14px;
  min-height: 90px;
  max-height: 140px;
  overflow-y: auto;
  border: 0 none;
}

.ck-body-wrapper .ck-powered-by-balloon {
  display: none !important;
}

.ck.ck-button.ck-on {
  background: var(--chakra-colors-grey-100);
  color: var(--chakra-colors-primary-green);
}

.ck.ck-button.ck-on:not(.ck-disabled):hover, a.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background: var(--chakra-colors-gray-100);
}

.ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus {
  border: 0 none;
  box-shadow: var(--chakra-colors-gray-100) 0 0;
}

.ck-content ul, .ck-content ol {
  margin-left: 15px;
}

.ck-content li {
  margin: 0;
}